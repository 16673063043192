<template>
  <div v-if="showCheckbox">
    <input
      id="form__newsletter"
      v-model="newsletter"
      class="form-check-input"
      name="newsletter"
      type="checkbox">
    <i18n
      path="globals.checkboxes.complianceNewsletter.text"
      tag="label"
      class="form-check-label"
      for="form__newsletter">
      <template v-slot:link>
        <a
          v-tooltip="$t('globals.privacyPolicyMarketing')"
          :href="linkHref"
          class="link link-underlined"
          target="_blank"
          @click="onClick(e)"
        >{{ $t('globals.checkboxes.complianceNewsletter.linkText') }}</a>
      </template>
    </i18n>
    <TextPageModal
      v-if="showModal"
      ref="modalPrivacyPolicyMarketing"
      :text-page-slug="linkSlug"
    />
  </div>
</template>

<script>
import TextPageModal from '@theme/components/utils/TextPageModal'

export default {
  components: {
    TextPageModal,
  },
  data() {
    return {
      linkSlug: this.$t('globals.pageSlugs.privacyPolicyMarketing'),
    }
  },
  computed: {
    linkHref() {
      return this.localePath({ name: 'page-slug', params: { slug: this.linkSlug } })
    },
    newsletter: {
      get() {
        return this.$store.state.cart.input.newsletter
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'newsletter', value: value })
      },
    },
    showCheckbox() {
      const { showCheckboxForLoggedUser } = this.$themeSettings.components.PageCheckoutInfo.newsletter
      return !this.$store.getters['user/userIsLoggedIn'] || showCheckboxForLoggedUser
    },
    showModal() {
      return this.$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal
    },
  },
  methods: {
    onClick() {
      if (this.$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal) {
        e.preventDefault()
        e.stopPropagation()
        this.$refs.modalPrivacyPolicyMarketing.show()
        return false
      }
      return true
    },
  },
}
</script>
